<template>
  <div class="place-page">

    <div class="float-end">
      <router-link to="/place/admin/add" class="btn btn-sm btn-secondary">Add gym</router-link>
    </div>
    <h1>Gym management</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>Title</th>
          <th class="text-center">Price</th>
          <th>Address</th>
          <th>Status</th>
          <th width="1%" class="text-nowrap text-center">Date create</th>
          <th width="1%"></th>
          <th width="1%"></th>
        </tr>
        </thead>
        <tbody v-if="placeList?.length > 0">
          <tr v-for="placeData in placeList" :key="placeData.id">
            <td class="text-nowrap">{{ placeData.id }}</td>
            <td>{{ placeData.title }}</td>
            <td class="text-center">
              {{ this.getPrice(placeData.price) }}{{ this.getCurrencySymbol() }}
            </td>
            <td>{{ placeData.address }}</td>
            <td>
              <span class="badge bg-secondary">
                {{ placeStatusEnum.getLabels()[placeData.status] }}
              </span>
            </td>
            <td class="text-nowrap text-center small">
              {{ this.getDateTime(placeData.dateCreate) }}
            </td>
            <td class="text-nowrap">
              <a href="#" class="fa fa-qrcode icon-fa" @click.prevent="doPlaceAdminCodeView(placeData.id)"></a>
            </td>
            <td class="text-nowrap">
              <router-link :to="{path: '/place/admin/edit/' + placeData.id}" class="fa fa-pencil-square icon-fa"></router-link>
              <a href="#" class="fa fa-minus-square icon-fa" @click.prevent="doPlaceAdminDelete(placeData.id)"></a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="placePager" :route="'/place/admin'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>
import PagerNav from "@/components/PagerNav";
import {PlaceStatusEnum} from "@/modules/place/enums/PlaceEnum";

export default {
  components: {PagerNav},

  data: () => ({
    placeList: [],
    placePager: {},
    placeStatusEnum: PlaceStatusEnum
  }),

  async mounted() {

    if (!await this.checkManager())
      return;

    this.setBreadcrumbs();

    await this.doPlaceAdminIndex();
  },

  methods: {

    async onPagerUpdate() {

      await this.doPlaceAdminIndex();
    },

    async doPlaceAdminIndex() {

      let apiHolder = await this.sendApiGet(['api/place/admin/index', {
        page: this.$route.query?.page || 1
      }]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeList)
        this.placeList = apiHolder.data.placeList;
      if (apiHolder.data?.placePager)
        this.placePager = apiHolder.data.placePager;
    },

    async doPlaceAdminDelete(placeId) {

      let apiHolder = await this.sendApiDelete(['api/place/admin/delete/' + placeId]);

      if (!apiHolder.isSuccess())
        return;

      this.placeList = this.placeList.filter((placeData) => {

        if (placeData.id != placeId)
          return placeData;
      });
    },

    async doPlaceAdminCodeView(placeId) {

      let apiHolder = await this.sendApiGet(['api/place/admin/code/view/' + placeId]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.codeUrl) {

        this.showModal({
          title: 'QR-code data',
          text: '<img src="' + apiHolder.data?.codeImage + '" class="qr-code-print" />'
        });
      }
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        'Gym management'
      ]);
    }

  }

}
</script>

<style scoped>

</style>