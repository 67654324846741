
const PlaceStatusEnum = {

	new: 'new',
	open: 'open',
	delete: 'delete',

	getLabels: function() {

		return {
			new: 'moderation',
			open: 'open'
		}
	}

};

module.exports = {PlaceStatusEnum};